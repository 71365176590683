.columns {
    display: flex;
}

.column {
    background: white;
    padding: 30px;
    border-radius: 10px;
    margin: 10px;
}

.column h3 {
    margin-bottom: 20px;
}

.formList {
    list-style: none;
    text-align: center;
    padding-left: 0%;
    margin-bottom: 0;
}

.cardList {
    list-style: none;
    text-align: center;
    padding-left: 0%;
    overflow-y: auto;
}

.cards {
    background-color: rgb(130, 196, 110);
    border-radius: 10px;
    padding: 10px;
    padding-top: 20px;
    width: 300px;
    margin: 10px;
}