.jsonDialog {
    max-width: 80%;
}

.formcomponents {
    position: fixed;
    z-index: 9999;
    top: 150px;
    right: 20px;
}

.container {
    margin-right: 220px;
}

.checkbox>label::before {
    border: 0px;
}

.form-check-label>input {
    padding: 10px;
    opacity: 1 !important;
    outline: none !important;
}

.Navigation {
    display: flex;
    justify-content: space-between;
}

.logs {
    display: flex;
    justify-content: space-between;
}

.logs button {
    margin: 15px;
}