
.add-new{
  color: #007bff;
  cursor: pointer;
}


.course-title{
  border: 1px Solid #61dafb;  
}

.course-title .field-description{
  
  background-color:#61dafb;
  padding: 10px;
}

.sitepager{
  float:right;
}

.sitepager .page-item.disabled > span.page-link{
  background-color:#eee;
}

th.sortable{
  color: #007bff;
  cursor: pointer;
}

th.nosort{
 color: #666; 
 font-weight: normal;
}

tr.clickable{
  cursor:pointer;  
}

tr.clickable:hover > td,tr.clickable.selected > td{
  background-color:#cee4fc;
}

span.sort-enabled{
  color: #000;  
  padding-left: 5px;
}

span.sort-disabled{
  color:#ccc;
  padding-left: 5px;
}

.tab-content{
  height: 100%;
}

.index-bigger-than-two{
  background-color: #007bff;
}